import React, { useContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Image from 'gatsby-image'
import Seo from "src/components/seoWp"
import SvgBack from 'src/components/switch/svgBack'
import logo from '../../static/logoBlack.svg'

import LayoutPortalBottom from 'src/components/layouts/layoutPortalBottom'
// import InterSectionTransition from 'src/components/layouts/interSectionTransition'

import WindowDriver from 'src/components/tools/windowDriver'

import LangSwitchUnit from 'src/components/common/langSwitchUnitSIMPLE'
import LangSwitchUnitMobile from 'src/components/common/langSwitchUnitSIMPLEmobile'

import ExtScript from 'src/components/common/extScript'

import {
  GlobalStateContext,
  // GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

// import logoTransWhite from 'src/images/transitionsImgs/logoWhite130h.svg'
// import logoTransBlack from 'src/images/transitionsImgs/logoBlack130h.svg'

// import slugMap from 'static/sectionsSlugs.js'

import 'src/styles/switch.scss'


const IndexPage = () => {
  const graphqlResult = useStaticQuery(graphql`
    query SwitchQuery {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
          line2GenralInfosInfo
        }
      }
      switchPage: wpPage(AcfGatsbyId: {pageid: {eq: "switch"}}){
        seo {
          focuskw
          title
          canonical
          opengraphUrl
          metaDesc
          schema {
            raw
          }
        }
        acfswitch {
          biznesTitleSwitchPage
          brandButtonTextSwitchPage
          businessButtonTextSwitchPage
          sportButtonTextSwitchPage
          sportTitleSwitchPage
          biznesImageSwitchPage {
            altText
            description
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          sportImageSwitchPage {
            altText
            description
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      privacyPolicy: wpPage(AcfGatsbyId: {pageid: {eq: "privacy-policy"}}) {
        slug
      }
    }
  `)

  const state = useContext(GlobalStateContext)

  // const slugMap = {
  //   'pl-PL': {
  //     brand: 'nasza-marka',
  //     sport: 'sport',
  //     business: 'biznes',
  //   },
  //   'en-EN': {
  //     brand: 'our-brand',
  //     sport: 'sport',
  //     business: 'business',
  //   },
  //   'de-DE': {
  //     brand: 'unsere-marke',
  //     sport: 'sport',
  //     business: 'geschaeft',
  //   }
  // }

  // const sectionsSlugs = require('./static/sectionsSlugs.js')



  const[sportImg, sportImgStatus] = useState('show')
  const[businessImg, businessImgStatus] = useState('hide')

  const handleSport = () => {
    sportImgStatus('show')
    businessImgStatus('hide')
  }
  const handleBusiness = () => {
    sportImgStatus('hide')
    businessImgStatus('show')
  }
  const currentLang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo



  // const maxHeightNow = typeof window !== `undefined` ? window.innerHeight : undefined
  // const htmlTag = typeof document !== `undefined` ? document.documentElement : undefined

  function heightInitial() {
    return typeof window !== `undefined` ? window.innerHeight : undefined
  }
  function htmlInitial() {
    return typeof document !== `undefined` ? document.documentElement : undefined
  }  
  const[maxHeightNow, setMaxHeightNow] = useState(() => heightInitial())
  const[htmlTag, setHtmlTag] = useState(() => htmlInitial())

  useEffect(() => {
    setMaxHeightNow(typeof window !== `undefined` ? window.innerHeight : undefined)
    setHtmlTag(typeof document !== `undefined` ? document.documentElement : undefined)
  }, [])
  
  useEffect(() => {
    if(typeof maxHeightNow !== 'undefined' && typeof htmlTag !== 'undefined') {
      htmlTag.style.setProperty(`--app-height`, `${maxHeightNow}px`)
    }
  }, [maxHeightNow, htmlTag])
  

  // console.log(maxHeightNow)
  // console.log(htmlTag)


  // const callback = function () {
  //   console.log('Done!!!!');
  // };
  // const verifyCallback = function (response) {
  //   console.log(response);
  // };


  return (
    <>

      <Seo seo={graphqlResult.switchPage.seo} site={`switch`}/>

      <div 
        id="maxFrame" 
        // style={{maxHeight:`${maxHeightNow}px`,height:`${maxHeightNow}px`}}
        style={{maxHeight:`calc(--app-height)`,height:`calc(--app-height)`}}
      >
        <div id={`centralImg`} className={`centralImg`}>
          <div className={`centralImgInner ${``}`}>
            <div className={`centralImgItem sportCentralImg ${sportImg}`}>
              <Image 
                fluid={graphqlResult.switchPage.acfswitch.sportImageSwitchPage.localFile.childImageSharp.fluid} 
                imgStyle={{maxWidth:'560px'}}
                alt={graphqlResult.switchPage.acfswitch.sportImageSwitchPage.altText}
                title={graphqlResult.switchPage.acfswitch.sportImageSwitchPage.title} 
              />
            </div>
            <div className={`centralImgItem businessCentralImg ${businessImg}`} >
              <Image 
                fluid={graphqlResult.switchPage.acfswitch.biznesImageSwitchPage.localFile.childImageSharp.fluid} 
                imgStyle={{maxWidth:'560px'}} 
                alt={graphqlResult.switchPage.acfswitch.biznesImageSwitchPage.altText}
                title={graphqlResult.switchPage.acfswitch.biznesImageSwitchPage.title}
              />
            </div>
          </div>
        </div>
          {/* {state.windowWidth <= 750 &&  */}
          <div className="lang-switch mobile" style={{fontWeight: '300',lineHeight:'32px'}}>
            <div className='langTitle'>
              <span>{graphqlResult.siteLanguage.acfgeneralinfos_layout.line2GenralInfosInfo}:</span>
            </div>
            <div className="lang-switchIN">
              {/* <LangSwitchUnitMobile site={pageContext.siteSlug} /> */}
              <LangSwitchUnitMobile site={``} />
            </div>
          </div>
          {/* } */}
        
        <div id="maxFrameInner"
        style={state.windowWidth > 750 ? {paddingTop: '33px'} : {}}
        >
          <SvgBack sportImg={sportImg} />
          <div id="logoWrap" className={`logoWrap logo`}>
            <div className={`logoWrapInner`}>
              <img src={logo} alt="modernforms logo" />
            </div>

            {/* <div className={`langWrap`}>
              <div className={`langWrapInner`}> */}

                  {/* <LangSwitchUnit site={''} page={''} multisection={false} subpage={undefined} /> */}
                {/* <div className="lang-switch" style={{fontWeight: '300', lineHeight:'30px'}}>
                  <LangSwitchUnit site={''} />
                </div> */}
                {/* {state.windowWidth > 750 && 
                <div className="lang-switch desktop" style={{fontWeight: '300',lineHeight:'30px'}}> */}
                  {/* <LangSwitchUnit site={pageContext.siteSlug} /> */}
                  {/* <LangSwitchUnit site={``} />
                </div>} */}
            {/* 
              </div>
            </div> */}
          </div>{' '}
          {/* #logoWrap END */}
          <div id="contentFrame" className={`contentFrame`}>
            {/* SPORT SECTION */}
            <div className={`viewSection sportSection`}
              onMouseEnter={handleSport}
              role="button"
              tabIndex="0"
            >
              <div className={`sectionInner`}>
                <h2
                  dangerouslySetInnerHTML={
                    graphqlResult.switchPage.acfswitch.sportTitleSwitchPage && {
                      __html: graphqlResult.switchPage.acfswitch.sportTitleSwitchPage,
                    }
                  }
                ></h2>
                <div className={`contentButtonsWrap sportButtons`}>
                  <button
                    type="button"
                    onClick={handleSport}
                    className={`primaryContentButton`}
                  >
                    <AniLink
                      to={`/sport/`}
                      duration={1.6}
                      fade

                      // top="entry"

                      // swipe
                      // direction="down"
                      // direction="right"
                      // entryOffset={400}
                    >
                      {graphqlResult.switchPage.acfswitch.sportButtonTextSwitchPage}
                    </AniLink>
                    <p className={`animateLink`}></p>
                  </button>
                </div>
              </div>
            </div>
            {/* END SPORT SECTION */}
            {/* BUSINESS SECTION */}
            <div className={`viewSection businessSection`}
              onMouseEnter={handleBusiness}
              role="button"
              tabIndex="0"
            >
              <div className={`sectionInner`}>
                <h2
                  dangerouslySetInnerHTML={
                    graphqlResult.switchPage.acfswitch.biznesTitleSwitchPage && {
                      __html: graphqlResult.switchPage.acfswitch.biznesTitleSwitchPage,
                    }
                  }
                ></h2>
                <div className={`contentButtonsWrap businessButtons`}>
                  <button
                    type="button"
                    onClick={handleBusiness}
                    className={`primaryContentButton`}
                  >
                    <AniLink
                      to={`/${currentLang === 'de-DE' ? 'Sonderveranstaltungen/' : currentLang === 'pl-PL' ? 'okolicznosciowe/' : 'occasional/'}`}
                      duration={1.6}
                      fade


                      // top="entry"

                      // swipe
                      // direction="right"
                      // entryOffset={400}
                    >
                      {graphqlResult.switchPage.acfswitch.businessButtonTextSwitchPage}
                    </AniLink>
                    <p className={`animateLink`}></p>
                  </button>
                </div>
              </div>
            </div>
            {/* END BUSINESS SECTION */}
            {/* BRAND SECTION */}
            <div className={`viewSection brandSection`}
              onMouseEnter={handleSport}
              role="button"
              tabIndex="0"
            >
              <div className={`sectionInner`}>
                <div className={`contentButtonsWrap brandButtons`}>
                  <button
                    type="button"
                    className={`primaryContentButton`}
                  >
                    <AniLink
                      to={`/${currentLang === 'de-DE' ? 'unsere-marke/' : currentLang === 'pl-PL' ? 'nasza-marka/' : 'our-brand/'}`}
                      duration={1.6}
                      fade

                      // top="entry"

                      // swipe
                      // direction="right"
                      // entryOffset={400}
                    >
                      <div
                        dangerouslySetInnerHTML={
                          graphqlResult.switchPage.acfswitch.brandButtonTextSwitchPage && {
                            __html:
                              graphqlResult.switchPage.acfswitch.brandButtonTextSwitchPage,
                          }
                        }
                      ></div>
                    </AniLink>
                    <p className={`animateLink`}></p>
                  </button>
                </div>
              </div>
            </div>

            {/* END BRAND SECTION */}
            <div className={`viewSection langSection`}
              onMouseEnter={handleBusiness}
              role="button"
              tabIndex="0"
            >
              <div className={`sectionInner`}></div>
            </div>
          </div>{' '}
          {/* #contentFrame END */}
        </div>{' '}
        {/* #maxFrameInner END */}
      </div>{' '}
      {/* #maxFrame END */}

      {/* <WindowDriver /> */}

      <LayoutPortalBottom level={`top`}>

        <WindowDriver />

        {/* <InterSectionTransition /> */}



        <ExtScript pagePath={'/'} />


      </LayoutPortalBottom>

    </>
  )
}
export default IndexPage