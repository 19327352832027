import React from 'react'
// import SvgGradient from 'src/components/tools/svgPrimitiveGradient'

const SvgBack = data => {
  return (
    <div
      id="svgContainer"
      className={`svgWrap`}
      // style={{ filter: "url(#noise)", }}
    >
      <div className={`svgWrapInner ${data.sportImg}-bgSvg`}>
        <svg
          // style={{ filter: "url(#colorManipulation)" }}
          // style={{ filter: "url(#noise)", }}
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 1 1116.213 1087.989"
          // fill={`url(#activeRegion)`}
          // fill={`url(#activeRegion2)`}

          // fill={`url(#grad11)`}
          // fill={`url(#grad12)`}

          fill={`#f3cf45`}

        >
          
          <defs>
            {/* <SvgGradient /> */}
          </defs>

        
          <path
            id="Path_7165"
            d="M-.856,83.57l626.495-1.033L930.357,1167-.076,1170.525Z"
            // d="M-.856,83.57l626.495-1.033L1115.357,1167-.076,1170.525Z"
            transform="translate(0.856 -82.536)"
            // fill="#f3cf45"
            // fill={`#f3cf45`}
            // fill={`url(#grad11)`}
          />
        </svg>
      </div>
    </div>
  )
}
export default SvgBack